.promotion-wrapper{
    height: 100vh;
    background-image: url('./intro-bg-color.png');
    background-position: 50% 72.95%;
    overflow-y: auto;
    padding-bottom: 50px;
   

}
.promotion-wrapper .container{
    padding-top: 15px;
}
.promotion-columns h1{
    font-size: 40px;
    margin: 0 0 60px 0 !important;
}
.promotion-columns h2{
    font-size: 22px;
    font-weight: 600 !important;
    margin: 0 !important;
}
.promotion-columns h2 p{
    font-size: 12px !important;
    margin: 10px 0 0 0 !important;
    text-transform: lowercase;
}
.promotion-columns h3{
    font-size: 32px;
    margin: 0 !important;
}
.promotion-columns h4{
    color: #fff;

}
.promotion-tc-text{
    font-size: 14px !important;
    color: #fff;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
    white-space: break-spaces;
}

.promotion-wrapper .heading{
    text-transform: uppercase;
    color: #fff !important;
    margin: 0 !;
} 
.promotion-wrapper  .promotion-columns-text{
    color: #fff !important;
    font-style: italic;
}

.promotion-wrapper .promotion-columns-hint{
    color: #fff !important;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: underline;
}

.promotion-wrapper .divider{
    height: 4px;
   
    width: 100%;
}
.promotion-onclick{
    width: 80%;
    padding: 16px;
    border: 1px solid #eee;
    border-radius: 8px;
    background-color: transparent;
    text-align: center;
    position: relative;
    margin: 0 0 25px 0;
    margin-top: auto;
    background: none;
    color: #fff;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: bold;
}
.promotion-onclick:hover{
    background-color: #f3eeee;
    color: #000;
    
}
.promotion-columns{
    display: flex;
    flex-direction: column;
    gap:16px;

   
   
}
.promotion-columns-text-center{
    text-align: center;
}
@media (max-width: 425px) {
    .promotion-columns h1{
        margin-bottom: 10px !important;
        font-size: 36px;

    }
    .promotion-columns h3{
        font-size: 25px;
    }
    .promotion-wrapper-icon{
        height: 80px;
    }
    .promotion-wrapper .promotion-columns-hint {
        font-size: 12px;
        font-weight: 600;
    }
  
}
.t-c-section{
    list-style-type: square;
    padding-left: 16px;

}
@media (max-width: 768px){
    .promotion-wrapper{
        background-position: 54.24% 67.37%;
        background-size: auto 100%;
    }
    .promotion-wrapper a img{
        width: 64px;
    }
    .t-c-section{
        max-width: 768;
    }
}
@media (min-width: 768px) {
    .promotion-wrapper .promotion-columns{
        width: 50%;
    }
}
@media (max-width: 767px) {
    .promotion-wrapper .promotion-columns{
        width: 100%;
    }
    .promotion-onclick{
        width: 100%;
    }
}
