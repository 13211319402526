
  
  .flex_column {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .overlay_background {
    display: flex;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 50;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.15);
  }
  
  .overlay_shown {
    opacity: 1;
  }
  
  .overlay_hidden {
    display: none;
    opacity: 0;
  }
  
  .overlay_card {
    max-width: 900px;
    border-radius: 10px;
    z-index: 51;
    background-color: rgb(256, 256, 256);
    display: flex;
    justify-content: center;
  }

